import type { Breadcrumbs } from 'src/types/breadcrumbs'
import type { MaybeRefOrGetter } from 'vue'
import { computed, toValue } from 'vue'
import { useI18n } from 'src/composables'
import RouterService from 'src/services/router.service'

export function useBlogBreadcrumbs(items?: MaybeRefOrGetter<Breadcrumbs | undefined>) {
  const { t, locale } = useI18n()

  return computed<Breadcrumbs>(() => {
    return [
      {
        id: 'blog',
        name: t('translate.news'),
        routeLocation: RouterService.getRouteLocation('blog', { locale: locale.value })
      },
      ...(toValue(items) || [])
    ]
  })
}
