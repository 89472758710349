import { useQuery } from 'src/composables'
import { BlogGroupList } from 'src/models/blog/group'
import type { MaybeRefOrGetter } from 'vue'

export function useApiBlogGetGroupsQuery(
  options?: Partial<{ enabled: MaybeRefOrGetter<boolean> }>
) {
  return useQuery({
    queryKey: ({ queryKeys, locale }) => queryKeys.blog.getGroups(locale).queryKey,
    queryFn: ({ api }) => api.blog.getGroups(),
    dataTransformer: (data) => new BlogGroupList(data),
    enabled: options?.enabled,
  })
}
