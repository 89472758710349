<template>
  <modal-aside class="blog-nav" :head="t('translate.categories')">
    <app-menu-button
      v-for="item in tabs"
      :key="item.id"
      :to="item.to"
      :label="item.label"
      :active="item.id === activeTab?.id"
      :icon="item.icon"
      class="blog-nav__button"
    />
  </modal-aside>
</template>

<script setup lang="ts">
import ModalAside from 'src/components/modal/ModalAside.vue'
import { useBlogGroupTabs, useApiBlogGetGroupsQuery, useI18n } from 'src/composables'

const { t } = useI18n()

const { data } = useApiBlogGetGroupsQuery()

const { tabs, activeTab } = useBlogGroupTabs(data)
</script>

<style scoped lang="scss">
.blog-nav {
  &__button {
    --menu-button-px: var(--modal-aside-px);
    margin: 0 calc(var(--modal-aside-px) * -1);
  }
}
</style>
