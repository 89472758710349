<template>
  <div class="blog-layout">
    <base-breadcrumbs
      v-if="breadcrumbs"
      :breadcrumbs="breadcrumbs"
    />

    <app-section>
      <app-container>
        <div class="blog-head">
          <h1 v-if="title" class="blog-head__title">{{ title }}</h1>
          <app-button
            class="blog-head__nav-button lt-lg"
            :label="t('translate.categories')"
            :icon="iconBars"
            color="primary"
            full-width
            @click="$q.dialog({ component: BlogNavModal })"
          />
        </div>

        <div class="blog-layout__inner">
          <div class="blog-layout__content">
            <slot name="content" />
          </div>

          <blog-nav class="blog-layout__nav gt-md" />
        </div>
      </app-container>
    </app-section>

    <slot />
  </div>
</template>

<script lang="ts" setup>
import iconBars from 'assets/icons/light/bars.svg?raw'
import BaseBreadcrumbs from 'src/components/base/BaseBreadcrumbs.vue'
import BlogNav from 'src/components/blog/BlogNav.vue'
import BlogNavModal from 'src/components/blog/BlogNavModal.vue'
import type { Breadcrumbs } from 'src/types/breadcrumbs'
import { useI18n } from 'src/composables/useI18n'

defineProps<{
  title?: string
  breadcrumbs?: Breadcrumbs
}>()

const { t } = useI18n()
</script>

<style lang="scss" scoped>
.blog-layout {
  &__inner {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(12, 1fr);
    gap: var(--grid-gutter);
  }
  &__content {
    grid-column: span 9;
    display: grid;
  }
  &__nav {
    grid-column: span 3;
    position: sticky;
    top: calc(var(--computed-header-height) + var(--grid-gutter));
  }
  @media (max-width: $breakpoint-md-max) {
    &__content {
      grid-column: span 12;
    }
  }
}

.blog-head {
  display: grid;
  align-items: center;
  gap: var(--md) var(--grid-gutter);
  grid-template-columns: 1fr 1fr;
  margin-bottom: var(--md);
  &__title {
    margin: 0;
  }
  @media (max-width: $breakpoint-sm-max) {
    grid-template-columns: 1fr;
    &__title {
      order: 2;
    }
  }
}
</style>
