import type { BlogGroupList } from 'src/models/blog/group'
import type { MaybeRefOrGetter } from 'vue'
import type { TabList } from 'src/types/tabs'
import { computed, toValue } from 'vue'
import { useRoute } from 'vue-router'
import RouterService from 'src/services/router.service'
import { RouteName } from 'src/types/router'
import { useI18n } from 'src/composables/useI18n'

export function useBlogGroupTabs(data: MaybeRefOrGetter<BlogGroupList | undefined>) {
  const { locale } = useI18n()

  const route = useRoute()

  const tabs = computed<TabList>(() => {
    return toValue(data)?.items.map(item => {
      const { slug, icon } = item
      const to = RouterService.getRouteLocation(RouteName.BlogGroup, { locale: locale.value, slug })
      const label = item.name || ''
      return { id: slug, label, icon, to }
    }) || []
  })

  const activeTab = computed(() => {
    if (route.name !== RouteName.BlogGroup) return
    return tabs.value.find(tab => tab.id === route.params.slug)
  })

  return { tabs, activeTab }
}
