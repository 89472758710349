<template>
  <div class="blog-nav">
    <route-tabs-aside
      :tabs="tabs"
      :active-tab="activeTab"
      :skeleton="!data || data.isEmpty"
      :title="t('translate.categories')"
    />
  </div>
</template>

<script lang="ts" setup>
import RouteTabsAside from 'src/components/RouteTabsAside.vue'
import { useBlogGroupTabs, useApiBlogGetGroupsQuery } from 'src/composables'
import { useI18n } from 'src/composables/useI18n'

const { t } = useI18n()

const { data } = useApiBlogGetGroupsQuery()

const { tabs, activeTab } = useBlogGroupTabs(data)
</script>

<style lang="scss" scoped>

</style>
